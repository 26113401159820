<template>
  <div>
    <CCardHeader>
      <CButton type="button" size="sm" color="info" @click="goBack"><CIcon name="cil-chevron-left" /></CButton>
    </CCardHeader>
    <CCardBody>
      <CForm id="submit" @submit.prevent="formSubmit">
        <div class="form-group">
          <CInputFile
            :class="{ 'is-invalid': $v.formData.file.$error }"
            custom
            :placeholder="filePlaceholder"
            @change="handleFileUpload"
          />
        </div>
        <small v-if="$v.formData.file.$error" class="form-error">{{ $t('errors.not_selected_file') }}</small>

        <div class="form-group">
          <label for="for-mobile" class="col-form-label-sm">{{ $t('creatives.for_mobile') }}</label>
          <CSwitch id="for-mobile" class="d-block" :checked.sync="formData.forMobile" color="info" />
        </div>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton form="submit" type="submit" :disabled="isSending" size="sm" color="info">
        {{ $t('ui.download') }}
      </CButton>
      <CButton class="ml-2" type="button" size="sm" color="danger" @click="goBack">{{ $t('ui.cancel') }}</CButton>
    </CCardFooter>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'CreativeMediaForm',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    initialValues: {
      type: Object,
      required: true,
    },
  },
  validations: {
    formData: {
      file: {
        required,
      },
    },
  },
  data() {
    return {
      filename: '',
      formData: {
        file: this.initialValues.file,
        forMobile: this.initialValues.forMobile,
      },
    };
  },
  computed: {
    filePlaceholder() {
      return this.filename ? this.filename : this.$t('form.choose_file');
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleFileUpload(file) {
      this.filename = file[0].name;
      this.formData.file = file[0];
    },
    formSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const data = new FormData();
      data.append('file', this.formData.file);
      data.append('forMobile', this.formData.forMobile);

      this.$emit('formSubmit', data);
    },
  },
};
</script>
