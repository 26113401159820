<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CreativeMediaForm :is-sending="isSending" :initial-values="initialValues" @formSubmit="onSubmit" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { UPLOAD_MEDIA } from '@/store/action-types/creatives';
import CreativeMediaForm from './CreativeMediaForm';

export default {
  name: 'CreativeMediaCreate',
  components: {
    CreativeMediaForm,
  },
  data() {
    return {
      initialValues: {
        file: '',
        forMobile: false,
      },
    };
  },
  computed: {
    ...mapState('creatives', ['isSending']),
    recordId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('creatives', [UPLOAD_MEDIA]),
    onSubmit(formData) {
      this.UPLOAD_MEDIA({ id: this.recordId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.file_downloaded'),
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.loading_error'),
          });
        });
    },
  },
};
</script>
